import {CurrentTimeProvider} from '../contexts/CurrentTime';
import {memo} from 'react';
import {
    EXPORT_ARTIFACT_STATUS_CANCELLED,
    EXPORT_ARTIFACT_STATUS_DONE,
    EXPORT_ARTIFACT_STATUS_PENDING,
    EXPORT_ARTIFACT_TYPE_DATEV,
    EXPORT_ARTIFACT_TYPE_DATEV_API,
    PROPERTY_DATEV_WITH_API,
} from '../constants';
import {dateFormat, isAfterLoad, translate} from '../utils';
import RelativeTime from './RelativeTime';

/**
 * Calls `callback` with a list of changed export artifacts, whenever external changes are detected.
 */
export function subscribeToChanges(ids, callback) {
    if (!ids.length) {
        return;
    }

    window.socket.on('export-artifact', exportArtifact => {
        if (exportArtifact.timestamp && !isAfterLoad(exportArtifact.timestamp)) return;

        // event triggers for other-type export artifacts as well
        if (ids.indexOf(exportArtifact.id) !== -1) {
            callback([exportArtifact]);
        }
    });

    const pendingIdQuery = new URLSearchParams();
    for (const id of ids) {
        pendingIdQuery.append('ids[]', id);
    }

    fetch('/export-artifact?' + pendingIdQuery)
        .then(response => response.json())
        .then(response => {
            // Assume that still pending exports have not changed
            const changedExportArtifacts = response.filter(exportArtifact => exportArtifact.status !== EXPORT_ARTIFACT_STATUS_PENDING);
            if (changedExportArtifacts.length) {
                callback(changedExportArtifacts);
            }
        });
}

export function ExportArtifacts({exportArtifacts}) {
    return <table className={'table'}>
        <thead>
        <tr>
            <th>{translate('frontend', 'EXPORT_ARTIFACT_LABEL_CREATED_AT')}</th>
            <th>{translate('frontend', 'EXPORT_ARTIFACT_LABEL_FINISHED_AT')}</th>
            <th>{translate('frontend', 'EXPORT_ARTIFACT_LABEL_EXPIRES_AT')}</th>
            <th>{translate('frontend', 'EXPORT_ARTIFACT_LABEL_STATUS')}</th>
            <th style={{width: 100}}></th>
        </tr>
        </thead>
        <tbody>
        <CurrentTimeProvider fidelity={'second'}>
            {exportArtifacts.map(exportArtifact => (
                <ExportArtifactRow key={exportArtifact.id} exportArtifact={exportArtifact}/>
            ))}
        </CurrentTimeProvider>
        </tbody>
    </table>;
}

const ExportArtifactRow = memo(({exportArtifact}) => {
    return <tr>
        <td>
            <RelativeTime datetime={exportArtifact.created_at * 1000}/>
        </td>
        <td>
            {exportArtifact.finished_at
                ? <RelativeTime datetime={exportArtifact.finished_at * 1000}/>
                : '—'}
        </td>
        <td>
            {exportArtifact.expires_at
                ? <RelativeTime datetime={exportArtifact.expires_at * 1000}/>
                : '—'}
        </td>
        <td>
            <ExportArtifactStatus status={exportArtifact.status}/>
        </td>
        <td className={'cell-with-actions'}>
            <ExportActions exportArtifact={exportArtifact}/>
        </td>
    </tr>;
});
ExportArtifactRow.displayName = 'ExportArtifactRow';

export function DatevExportArtifacts({exportArtifacts}) {
    // Datev api might have been enabled in the past and is now disabled. As long as there
    // are different entries, they should be able to be differentiated.
    const showTypeColumn = window.config.property.datev_access === PROPERTY_DATEV_WITH_API ||
        exportArtifacts.find(exportArtifact => exportArtifact.type === 'api') !== undefined;

    return <table className={'table'}>
        <thead>
        <tr>
            {showTypeColumn ? <th style={{width: '1%'}}>{translate('frontend', 'EXPORT_ARTIFACT_TYPE_LABEL')}</th> : null}
            <th>{translate('frontend', 'EXPORT_ARTIFACT_LABEL_CREATED_AT')}</th>
            <th>{translate('frontend', 'EXPORT_ARTIFACT_LABEL_FINISHED_AT')}</th>
            <th>{translate('frontend', 'EXPORT_ARTIFACT_LABEL_EXPIRES_AT')}</th>
            <th>{translate('frontend', 'GOBD_EXPORT_START_DATE')}</th>
            <th>{translate('frontend', 'GOBD_EXPORT_END_DATE')}</th>
            <th>{translate('frontend', 'EXPORT_ARTIFACT_LABEL_STATUS')}</th>
            <th style={{width: 100}}></th>
        </tr>
        </thead>
        <tbody>
        <CurrentTimeProvider fidelity={'second'}>
            {exportArtifacts.map(exportArtifact => (
                <DatevExportArtifactRow key={exportArtifact.id} exportArtifact={exportArtifact} showTypeColumn={showTypeColumn}/>
            ))}
        </CurrentTimeProvider>
        </tbody>
    </table>;
}

const DatevExportArtifactRow = memo(({exportArtifact, showTypeColumn}) => {
    const typeLabel = translate(
        'frontend',
        exportArtifact.type === EXPORT_ARTIFACT_TYPE_DATEV ? 'EXPORT_ARTIFACT_TYPE_LABEL_DATEV' : 'EXPORT_ARTIFACT_TYPE_LABEL_DATEV_API',
    );

    return <tr>
        {showTypeColumn ? <td style={{width: '1%'}}>
            <div className="label label-default">{typeLabel}</div>
        </td> : null}
        <td>
            <RelativeTime datetime={exportArtifact.created_at * 1000}/>
        </td>
        <td>
            {exportArtifact.finished_at
                ? <RelativeTime datetime={exportArtifact.finished_at * 1000}/>
                : '—'}
        </td>
        <td>
            {exportArtifact.expires_at
                ? <RelativeTime datetime={exportArtifact.expires_at * 1000}/>
                : '—'}
        </td>
        <td>{exportArtifact.metadata?.start_date ? dateFormat(exportArtifact.metadata?.start_date) : '—'}</td>
        <td>{exportArtifact.metadata?.end_date ? dateFormat(exportArtifact.metadata?.end_date) : '—'}</td>
        <td>
            <ExportArtifactStatus status={exportArtifact.status}/>
        </td>
        <td className={'cell-with-actions'}>
            <ExportActions exportArtifact={exportArtifact}/>
        </td>
    </tr>;
});
DatevExportArtifactRow.displayName = 'DatevExportArtifactRow';

function ExportActions({exportArtifact}) {
    const actions = [];
    if (exportArtifact.download) {
        actions.push(
            <a key={'download'} className={'btn btn-link'} href={exportArtifact.download} title={translate('frontend', 'EXPORT_ARTIFACT_ACTION_DOWNLOAD')} download>
                <i className={'fa-regular fa-file-arrow-down'}/>
            </a>,
        );
    }
    if (exportArtifact.type === EXPORT_ARTIFACT_TYPE_DATEV_API && (exportArtifact.status === EXPORT_ARTIFACT_STATUS_DONE || exportArtifact.status === EXPORT_ARTIFACT_STATUS_CANCELLED)) {
        const hasErrors = !!exportArtifact.metadata?.response?.validation_details;
        actions.push(
            <a key={'datev_api_errors'} className={'btn btn-link'} href={`/native-datev-export/${exportArtifact.id}`} title={translate('frontend', 'EXPORT_ARTIFACT_ACTION_DETAILS')}>
                <i className={`fa-regular fa-info-circle ${hasErrors ? 'text-danger' : ''}`}></i>
            </a>,
        );
    }

    return actions;
}

export function ExportArtifactStatus({status}) {
    if (status === EXPORT_ARTIFACT_STATUS_DONE) {
        return <span className={'label label-success'}>
            {translate('frontend', 'EXPORT_ARTIFACT_STATUS_DONE')}
        </span>;
    } else if (status === EXPORT_ARTIFACT_STATUS_PENDING) {
        return <span className={'label label-warning'}>
            {translate('frontend', 'EXPORT_ARTIFACT_STATUS_PENDING')}
        </span>;
    } else if (status === EXPORT_ARTIFACT_STATUS_CANCELLED) {
        return <span className={'label label-danger'}>
            {translate('frontend', 'EXPORT_ARTIFACT_STATUS_CANCELLED')}
        </span>;
    } else {
        return null;
    }
}
