import {
    clientSearch,
    confirmModal,
    createReservationClientModal,
    createReservationModal,
    editClientModal,
    editPersonModal,
    editSpecialEventModal,
    emailSendModal,
    enquiryRoomDetailsModal,
    logoUploadModal,
    maintenanceModal,
    maintenanceTaskModal,
    productModal,
    removeGuestConfirmModal,
    roomBlockUpdate,
    roomDetailsModal,
    showAddRoomsModal,
    specialEventsImportModal,
    touristTaxModal,
    vostioReadCardModal,
} from './modals';
import $ from 'jquery';
import addFromTemplate from './add_from_template';
import {dateYMD, debounce, getCountryRegions, getLocalDayNames, getLocalMonthNames, isAfterLoad, rgbToHex, translate} from './utils';
import {isCellToday, isCellWeekend} from './dpUtils';
import {isSupportedBrowser, showSiteMessages} from './site_message';
import {createRoot} from 'react-dom/client';
import Shell from './components/Shell';
import FilterSelection from './components/FilterSelection/FilterSelection';
import {startLoadingIndicator} from './loading';
import {DatevExportArtifacts, ExportArtifacts, subscribeToChanges} from './components/ExportArtifacts.jsx';
import {flushSync} from 'react-dom';
import {EXPORT_ARTIFACT_STATUS_PENDING} from './constants';
import {disableSubmitButton, enableSubmitButton} from './enable_disable_button';
import ChannelManagerNoticesList from './components/ChannelManagerNoticesList';

require('admin-lte/dist/js/app');

$.AdminLTE.options.enableBSToppltip = false;
$.AdminLTE.options.animationSpeed = 200;
$.AdminLTE.options.boxWidgetOptions.boxWidgetIcons.collapse = 'fa-chevron-up';
$.AdminLTE.options.boxWidgetOptions.boxWidgetIcons.open = 'fa-chevron-down';

// Prevents setting min-height to .wrapper element (since new layout is dynamic we don't need this anymore)
$.AdminLTE = new Proxy($.AdminLTE, {
    set(obj, prop, value) {
        if (prop === 'layout') {
            obj.layout = {
                activate: () => {},
                fix: () => {},
                fixSidebar: () => {},
            };
        } else {
            obj[prop] = value;
        }

        return true;
    }
});

$(document)
    .on('expanded.pushMenu', () => updateUserSidebarState(1))
    .on('collapsed.pushMenu', () => updateUserSidebarState(0));

function updateUserSidebarState(state) {
    document.cookie = 'menu_state=' + state + ';path=/';
    if (state === 0) {
        $('#site-message').css('padding-left', '80px');
    } else {
        $('#site-message').css('padding-left', '260px');
    }
}

const reloadChannel = 'BroadcastChannel' in window ? new BroadcastChannel('force_reload') : null;
if (reloadChannel) {
    reloadChannel.onmessage = () => {
        startLoadingIndicator();
        window.location.reload();
    };
}

$(document).ready(() => {
    $('[data-toggle="popover"]').popover({
        trigger: 'hover',
    });
    addFromTemplate.load();

    // Prevent Bootstrap dialog from blocking focusin
    // Copy pasta from: https://www.tiny.cloud/docs-4x/integrations/bootstrap/#usingtinymceinabootstrapdialog
    $(document).on('focusin', function (e) {
        if ($(e.target).closest('.mce-window').length) {
            e.stopImmediatePropagation();
        }
    });

    // Prevent number inputs from changing value when input is focused, hovered over and scrolling
    document.addEventListener("wheel", (event) => {
        if (document.activeElement.type === "number" && document.activeElement === event.target) {
            event.preventDefault();
        }
    }, { passive: false });

    if (typeof lightbox !== 'undefined') {
        lightbox.option({
            'fadeDuration': 200,
            'imageFadeDuration': 200,
            'resizeDuration': 200,
        });
    }

    document.getElementById('readKeyCard')?.addEventListener('click', event => {
        vostioReadCardModal();
    });
});

// These are called from inline javascripts, hence the need for a global export
window.createReservationModal = createReservationModal;
window.createReservationClientModal = createReservationClientModal;
window.clientSearch = clientSearch;
window.confirmModal = confirmModal;
window.removeGuestConfirmModal = removeGuestConfirmModal;
window.maintenanceModal = maintenanceModal;
window.maintenanceTaskModal = maintenanceTaskModal;
window.registerNewlyAddedInputAsYiiActiveField = addFromTemplate.registerNewlyAddedInputAsYiiActiveField;
window.debounce = debounce;
window.showAddRoomsModal = showAddRoomsModal;
window.emailSendModal = emailSendModal;
window.editPersonModal = editPersonModal;
window.editClientModal = editClientModal;
window.touristTaxModal = touristTaxModal;
window.editSpecialEventModal = editSpecialEventModal;
window.roomBlockUpdate = roomBlockUpdate;
window.roomDetailsModal = roomDetailsModal;
window.enquiryRoomDetailsModal = enquiryRoomDetailsModal;
window.productModal = productModal;
window.getCountryRegions = getCountryRegions;
window.specialEventsImportModal = specialEventsImportModal;
window.showSiteMessages = showSiteMessages;
window.isSupportedBrowser = isSupportedBrowser;
window.isCellToday = isCellToday;
window.isCellWeekend = isCellWeekend;
window.getLocalDayNames = getLocalDayNames;
window.getLocalMonthNames = getLocalMonthNames;
window.enableSubmitButton = enableSubmitButton;
window.disableSubmitButton = disableSubmitButton;
window.rgbToHex = rgbToHex;
window.isAfterLoad = isAfterLoad;

$(document).on('hidden.bs.modal', function () {
    // Some workflows require multiple modals open at the same time (client search, editing a product, etc.)
    // `overflow:hidden` should remain set until the last modal is closed so that scrolling continues to work.
    // A workaround for bootstrap not supporting nested modals by default.

    if ($('.modal').toArray().some((modal) => $(modal).data('bs.modal')?.isShown)) {
        document.body.classList.add('modal-open');
    } else {
        // When a model is closed & opened at the same time, scrollbar's padding
        // isnt removed when closing the 2nd modal.
        document.body.style.removeProperty('padding-right');
    }
});

// Adjust dropdown menu position to make sure it's visible (from default:left position to right if needed)
$(document).on('show.bs.dropdown', function (event) {
    const dropdown = event.target.querySelector('.dropdown-menu');
    if (!dropdown) return

    dropdown.style.display = 'block';

    const dropdownRect = dropdown.getBoundingClientRect();
    const dropdownButtonRect = event.target.getBoundingClientRect();
    const isInitialPositionOnLeft = !dropdown.classList.contains('dropdown-menu-right')
    let shouldRepositionToFullScreen = false;

    if (isInitialPositionOnLeft && dropdownRect.left + dropdownRect.width > document.documentElement.clientWidth) {
        // Check if dropdown would overflow to the left of viewport if positioned on the right
        if (dropdownRect.width > dropdownButtonRect.right) {
            shouldRepositionToFullScreen = true;
        } else {
            dropdown.classList.add('dropdown-menu-right');
            dropdown.dataset.forcedRight = 'true';
        }
    } else if (dropdownRect.left < 0) {
        dropdown.classList.remove('dropdown-menu-right');
        dropdown.dataset.wasOnRight = 'true';
        shouldRepositionToFullScreen = true
    }

    if (shouldRepositionToFullScreen) {
        dropdown.style.left = `${-dropdownButtonRect.left}px`; // position on the left side of viewport
        dropdown.style.right = 'unset';
        dropdown.style.maxWidth = `${document.documentElement.clientWidth}px`;
        dropdown.style.overflowX = 'auto';
        dropdown.dataset.forcedFullScreen = 'true';
    }

    dropdown.style.removeProperty('display');
})

// Remove adjusted dropdown menu position attributes when dropdown is closed
$(document).on('hidden.bs.dropdown', function (event) {
    const dropdown = event.target.querySelector('.dropdown-menu');
    if (!dropdown) return

    if (dropdown.dataset.forcedRight === 'true') {
        delete dropdown.dataset.forcedRight;
        dropdown.classList.remove('dropdown-menu-right');
    } else if (dropdown.dataset.forcedFullScreen === 'true') {
        delete dropdown.dataset.forcedFullScreen;
        dropdown.style = '';

        // If initial position was right, reapply it
        if (dropdown.dataset.wasOnRight === 'true') {
            dropdown.classList.add('dropdown-menu-right');
        }
    }
})

document.addEventListener('DOMContentLoaded', function () {
    const logoElement = document.querySelector('.logo')
    if (logoElement && logoElement.dataset.enabled === 'true') {
        logoElement.addEventListener('click', function (event) {
            event.preventDefault();
            logoUploadModal();
        });
    }
});

window.initExportArtifacts = function (container, initialExportArtifacts, type = 'default') {
    const root = createRoot(container);
    let exportArtifacts = initialExportArtifacts;
    const render = () => {
        let Component;
        if (type === 'datev') {
            Component = DatevExportArtifacts;
        } else {
            Component = ExportArtifacts;
        }
        root.render(<Shell><Component exportArtifacts={exportArtifacts}/></Shell>);
    };

    flushSync(() => {
        render(initialExportArtifacts);
    });

    const pendingIds = initialExportArtifacts.filter(({status}) => status === EXPORT_ARTIFACT_STATUS_PENDING).map(({id}) => id);
    subscribeToChanges(pendingIds, newExportArtifacts => {
        for (const exportArtifact of newExportArtifacts) {
            const index = exportArtifacts.findIndex(({id}) => id === exportArtifact.id);
            if (index !== -1) {
                // Some fields may not be provided
                exportArtifacts[index] = {...exportArtifacts[index], ...exportArtifact};
            }
        }
        render();
    });
};

// Allows initializing toolbar synchronously, avoiding "flashes"
window.initFilterToolbar = function (container) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Currently only used for invoices list
    const schema = {
        status_text: {
            label: translate('frontend', 'STATUS'),
            type: 'enum',
            values: {
                paid: {
                    label: translate('frontend', 'Paid'),
                },
                partial: {
                    label: translate('frontend', 'Partial'),
                },
                open: {
                    label: translate('frontend', 'Open'),
                },
                overpaid: {
                    label: translate('frontend', 'Overpaid'),
                },
                cancelled: {
                    label: translate('frontend', 'Cancelled'),
                },
                irrecoverable: {
                    label: translate('frontend', 'Irrecoverable'),
                },
                closed: {
                    label: translate('frontend', 'Closed'),
                },
            },
        },
        invoice_type: {
            label: translate('frontend', 'Type'),
            type: 'enum',
            values: {
                0: {
                    label: translate('frontend', 'INVOICE_TYPE_NORMAL'),
                },
                1: {
                    label: translate('frontend', 'INVOICE_TYPE_REVERSED'),
                },
                2: {
                    label: translate('frontend', 'INVOICE_TYPE_CREDITNOTE'),
                },
            },
        },
        dunning_level: {
            label: translate('frontend', 'Dunning Level'),
            type: 'enum',
            values: {
                1: {
                    label: '1',
                },
                2: {
                    label: '2',
                },
                3: {
                    label: '3',
                },
                4: {
                    label: '4',
                },
            },
        },
        invoice_created_by: {
            label: translate('frontend', 'CREATED_BY'),
            type: 'string',
        },
        arriving_clients: {
            label: translate('frontend', 'Arriving Clients'),
            type: 'string',
        },
        invoice_due_date: {
            label: translate('frontend', 'DUE_DATE'),
            type: 'date',
        },
        invoice_open_amount: {
            label: translate('frontend', 'OPEN_AMOUNT'),
            type: 'decimal',
        },
        invoice_net_amount: {
            label: translate('frontend', 'NET'),
            type: 'decimal',
        },
        vat1_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 1, unit: '%'}),
            type: 'decimal',
        },
        vat1_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 1, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat2_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 2, unit: '%'}),
            type: 'decimal',
        },
        vat2_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 2, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat3_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 3, unit: '%'}),
            type: 'decimal',
        },
        vat3_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 3, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat4_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 4, unit: '%'}),
            type: 'decimal',
        },
        vat4_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 4, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat5_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 5, unit: '%'}),
            type: 'decimal',
        },
        vat5_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 5, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat6_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 6, unit: '%'}),
            type: 'decimal',
        },
        vat6_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 6, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat7_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 7, unit: '%'}),
            type: 'decimal',
        },
        vat7_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 7, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat8_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 8, unit: '%'}),
            type: 'decimal',
        },
        vat8_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 8, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat9_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 9, unit: '%'}),
            type: 'decimal',
        },
        vat9_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 9, unit: window.currencySymbol}),
            type: 'decimal',
        },
        vat10_percents: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 10, unit: '%'}),
            type: 'decimal',
        },
        vat10_amount: {
            label: translate('frontend', 'VAT_AND_TAX_CATEGORY', {category: 10, unit: window.currencySymbol}),
            type: 'decimal',
        },
        invoice_number: {
            label: translate('frontend', 'Invoice Number'),
            type: 'string',
        },
        invoice_datetime: {
            label: translate('frontend', 'Date'),
            type: 'date',
            suggestions: [{
                label: translate('frontend', 'FILTER_SELECTION_SUGGESTION_THIS_MONTH'),
                operators: {
                    ge: dateYMD(new Date(currentYear, currentMonth, 1)),
                    le: dateYMD(new Date(currentYear, currentMonth + 1, 0)),
                },
            }, {
                label: translate('frontend', 'FILTER_SELECTION_SUGGESTION_LAST_MONTH'),
                operators: {
                    ge: dateYMD(new Date(currentYear, currentMonth - 1, 1)),
                    le: dateYMD(new Date(currentYear, currentMonth, 0)),
                },
            }, {
                label: translate('frontend', 'FILTER_SELECTION_SUGGESTION_THIS_YEAR'),
                operators: {
                    ge: `${currentYear}-01-01`,
                    le: `${currentYear}-12-31`,
                },
            }, {
                label: translate('frontend', 'FILTER_SELECTION_SUGGESTION_LAST_YEAR'),
                operators: {
                    ge: `${currentYear - 1}-01-01`,
                    le: `${currentYear - 1}-12-31`,
                },
            }],
        },
        gross_amount: {
            label: translate('frontend', 'Gross'),
            type: 'decimal',
        },
        invoice_recipient: {
            label: translate('frontend', 'Recipient'),
            type: 'string',
        },
        payment_method: {
            label: translate('frontend', 'Payment Method'),
            type: 'string',
        },
    };

    const filter = JSON.parse(container.dataset.filter);
    const suggestedFieldNames = container.dataset.suggestedFieldNames ? JSON.parse(container.dataset.suggestedFieldNames) : [];

    flushSync(() =>
        createRoot(container).render(
            <Shell><FilterSelection schema={schema} filter={filter} suggestedFieldNames={suggestedFieldNames} applyFilters={applyFilters}/></Shell>,
        )
    );
};

function applyFilters(filter) {
    startLoadingIndicator();

    // Remove current "filter" fields while keeping any other ones (limit, page, etc.)
    const searchParams = new URLSearchParams(window.location.search);
    [...searchParams.keys()].map(key => {
        // Removing `filter` does not remove `filter[created_at]`
        if (key.includes('filter[') || key === 'filter') {
            searchParams.delete(key);
        }
    });

    const existingQuery = searchParams.toString();
    const newQuery = jQuery.param({
        // There may be a default filter applied for when filter is not present.
        // When all filters are explicitly removed, use an empty `filter` param.
        filter: Object.keys(filter).length ? filter : '',
    });
    const newSearch = existingQuery.length ? `${existingQuery}&${newQuery}` : newQuery;
    window.location.search = decodeURIComponent(newSearch);
}

window.initChannelManagerNotices = function (container, notices, isOpenable) {
    flushSync(() =>
        createRoot(container).render(
            <Shell>
                <ChannelManagerNoticesList initialNotices={notices} isOpenable={isOpenable}/>
            </Shell>
        )
    );
};

document.addEventListener('DOMContentLoaded', () => {
    document
        .querySelectorAll('input[type=checkbox][data-toggle=visibility]')
        .forEach(
            (toggle) => toggle.addEventListener(
                'click',
                (event) => {
                    const element = document.getElementById(event.target.dataset.target);
                    element.style.display = event.target.checked ? 'block' : 'none';
                },
            )
        );
})
